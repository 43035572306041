.simple-snackbar-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 4rem;
  margin: 0px 10px;
  z-index: 100000;
  border-radius: 0.5rem;
  box-shadow: 3px 3px 3px #000;
}

.simple-snackbar {
  padding: 16px;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  text-align: center;
  justify-items: center;
}

.simple-snackbar-success {
  background-color: #198754;
  color: #fff;
}

.simple-snackbar-info {
  background-color: #0d6efd;
  color: #fff;
}

.simple-snackbar-warning {
  background-color: #ffc107;
  color: #000;
}

.simple-snackbar-error {
  background-color: #dc3545;
  color: #fff;
}

.simple-snackbar-content {
  display: flex;
  font-size: 1.2rem;
}

.simple-snackbar-close {
  font-size: 1.2rem;
  cursor: pointer;
}
