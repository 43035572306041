.header {
  color: white;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  height: 3rem;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-title {
  font-size: 1.2rem;
  cursor: pointer;
  user-select: none;
}

.header-icon-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-right: 0.5rem;
  cursor: pointer;
  user-select: none;
}

.header-icon {
  font-size: 1.2rem;
  align-items: center;
}
