html {
  height: 100vh;
}

.wrapper {
  height: 100vh;
}

.layout-content {
  height: 100%;
}

.layout-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  height: calc(100% - 3rem);
}

.layout-navigation {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #eee;
  width: 250px;
  padding: 10px;
}

.layout-inner-content {
  width: 100%;
  overflow-y: auto;
  padding-right: 10px;
  padding-left: 10px;
}

.layout-navigation-elem {
  padding: 8px;
  margin: 2px;
  cursor: pointer;
  border-radius: 0.3rem;
}

.layout-navigation-elem:hover {
  background-color: #fff;
}

.layout-navigation-elem-active {
  padding: 8px;
  margin: 2px;
  cursor: pointer;
  background-color: #0d6efd;
  color: #fff;
  font-weight: bold;
  border-radius: 0.3rem;
}
