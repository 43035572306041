.table-edit-column {
  width: 25px;
}

.table-delete-column {
  width: 25px;
}

.table-icon-cell {
  font-size: 1.4rem;
  cursor: pointer;
}

.table-icon-cell:hover {
  background-color: #ccc;
}

.table-thumb-image {
  width: 200px;
}
